<template>
    <div class="lay_container">
        <Header></Header>
        <div class="cont_m" @mousedown="handleonmousedown($event)">
            <div class="max_cont_width flex_box">
                <!-- <Menu></Menu> -->
                <div class="left_menu" v-loading="loadig">
                    <ul class="de_menu_list">
                        <li class="de_menu_item" v-for="(item, index) in menuTitleList" :key="index">
                            <div class="flex_center_between_box" v-if="item.status == false" :class="{ noitclick: item.status == true }">
                                <span class="de_menu_item_a" :class="{ 'router-link-active': (linkActive == index && $route.name === item.title.replace(/\s/g, '')) || ($route.name == 'ImportAndExport' && linkActive == index), noitclick: item.status == true.valueOf, import: index == '6--1' }" @click="handlFristMenu(index, item, -1), (linkActive = index)">
                                    <div class="title flex_center" :class="linkActive == index && linkActive != -1 ? 'subOn' : ''">
                                        {{ item.title }}
                                        <span class="el-icon-lock" style="font-size: 20px; font-weight: 600; position: absolute; left: 220px" v-if="!item.power && item.id != '7001'"></span>
                                    </div>
                                </span>
                                <div class="show_ic" @click="showSubMenu(item, index)" v-if="item.result.length != 0" :class="{ open: item.disBol == true, close: item.disBol == false }"></div>
                            </div>
                            <transition name="sub-comments">
                                <ul class="de_menu_sublist" v-show="item.disBol">
                                    <li class="de_menu_subitem" v-for="(subItem, i) in item.result" :key="i">
                                        <div v-if="subItem.status == false" :class="{ noitclickk: subItem.status == true }" @click="handlFristMenu(index, subItem, i)">
                                            <span class="de_menu_subitem_a" :class="{ 'router-link-active': subLinkActive == index + '-' + i }">
                                                {{ subItem.name }}
                                                <span class="el-icon-lock" style="font-size: 16px; font-weight: 600; position: absolute; left: 190px; top: 10px" v-if="!subItem.power"></span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </transition>
                        </li>
                    </ul>
                </div>
                <div class="right_box">
                    <!-- <RightHeader @getIsTranslate="getIndexIsTranslate" :companyObj="companyBase"></RightHeader> -->
                    <!-- 手机端 -->
                    <div class="phone_page_bg phone_overfflow sna_box">
                        <div class="phone_left_menu">
                            <ul class="de_menu_list">
                                <li class="de_menu_item" v-for="(item, index) in menuTitleList" :key="index">
                                    <div class="flex_center_between_box phone_flex_center_between_box" v-if="item.status == false" :class="{ noitclick: item.status == true }">
                                        <span class="de_menu_item_a" :class="{ 'router-link-active': (linkActive == index && $route.name === item.title.replace(/\s/g, '')) || ($route.name == 'ImportAndExport' && linkActive == index), noitclick: item.status == true.valueOf, import: index == '6--1' }" @click="handlFristMenu(index, item, -1), (linkActive = index)">
                                            <div class="title flex_center" :class="['ic_' + index, linkActive == index && linkActive != -1 ? 'subOn' : '']">
                                                {{ item.title }}
                                                <span class="el-icon-lock" style="font-size: 14px; font-weight: 600; position: absolute; right: -7px" v-if="!item.power && item.id != '7001'"></span>
                                            </div>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="flex_center_between_box Phone_head_title">
                        <div class="c_detail_top_left line_2">
                            <div v-if="indexIsTranslate" class="c_d_title">{{ companyBase?.name }}</div>
                            <div v-if="!indexIsTranslate" class="c_d_title">{{ companyBase?.nameEn }}</div>
                            <div class="flex_between_box computer_right_cont_box">
                                <div :class="{ c_d_tag: true }" @click="tabshow($event)">
                                    <el-tag v-for="(item, i) in companyBase.tags" :key="i">
                                        <span>{{ item.lableEn }}</span>
                                    </el-tag>
                                </div>
                            </div>
                        </div>
                        <div class="c_detail_top_right">
                            <div class="c_d_buttom flex_box computer_right_cont_box">
                                <!-- <el-popover placement="bottom" trigger="hover" width="240" popper-class="pop_link"> -->
                                <button class="btn_blue flex_center" slot="reference" @click="compareCompanyCountrypower('Company')">
                                    <img src="../../assets/img/detail/btn_ic1.png" />
                                    Compare
                                </button>

                                <el-tooltip v-if="updateDisabled" content="It normally takes 2-4 days to deliver,this updated rerpot." :visible-arrow="false" placement="top" effect="light">
                                    <button class="btn_gray">In Production...</button>
                                </el-tooltip>
                                <button :disabled="companyBase?.nameEn.length > 0 ? false : true" class="btn_blue flex_center" v-else @click="getpdffsuo('offlineReport')">
                                    <img src="../../assets/img/detail/btn_ic2.png" />
                                    Update
                                </button>
                                <button class="btn_blue flex_center" @click="detailReportError">
                                    <img src="../../assets/img/detail/btn_ic3.png" />
                                    Report Error
                                </button>
                            </div>
                            <div class="c_d_icbtn">
                                <el-tooltip class="item" popper-class="tool_tip" :visible-arrow="false" effect="light" content="Translate" placement="bottom">
                                    <el-button class="tool_blue" @click="btnTranslate">
                                        <img id="fan_yi" src="../../assets/img/detail/head_ic1.png" />
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip class="item" popper-class="tool_tip tool_tip2" effect="light" :visible-arrow="false" content="Download Online Report" placement="bottom">
                                    <el-button class="tool_blue" @click="getpdffsuo('onlineReport')">
                                        <img src="../../assets/img/detail/head_ic3.png" />
                                    </el-button>
                                </el-tooltip>

                                <el-tooltip class="item" popper-class="tool_tip tool_tip3" :visible-arrow="false" effect="light" content="Add to My List" placement="bottom">
                                    <el-button v-if="isfalow == true" class="tool_add" @click="addToList"><img src="../../assets/img/detail/head_ic2.png" /></el-button>
                                    <el-button v-else class="" @click="addToList"><img src="../../assets/img/detail/head_ic2.png" /></el-button>
                                </el-tooltip>
                                <!-- <div class="phone_page_bg" style="margin: 0px 0 0 10px !important">
                                    <i class="zhankai" @click="applefilters"><img src="../../assets/img/liebiao2.png" alt="" /></i>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <router-view @updateSubLink="updateSubLinkParent"></router-view>
                </div>
            </div>
            <!-- <div class="phone_page_bg">
                <div class="phone_flex">
                    <el-button class="btn_export" @click="More()">More</el-button>
                </div>
            </div> -->
        </div>
        <Footer v-if="window.innerWidth > 821"></Footer>
        <!-- 收藏弹框 -->
        <Collection :fromPage="fromPage" @childCloseDialog="closeCollageDialog(arguments)" :addList.sync="addList" :addPop="addPopIndex" :collecObj="collecObj"></Collection>

        <!-- 报错弹框 -->
        <Reporterror :showReport="showReportIndex" :companynameen="companyBase?.nameEn" @closeReportDialog="closeDialog($event)" :powerStatus="powerToReportErrorStatus"></Reporterror>
        <!-- 锁 -->

        <Suo v-if="window.innerWidth > 821" :suoClose="suoclose" :obj="xuanzeobj" @closeCloseDialog="clossuoDialog($event)" @closeCloseDialog1="clossuoDialog1($event)" :update="update" :powerStatus="powerToReportErrorStatus"></Suo>
        <Phonesuo v-else :suoClose="suoclose" :obj="xuanzeobj" @closeCloseDialog="clossuoDialog($event)" @closeCloseDialog1="clossuoDialog1($event)" :update="update" :powerStatus="powerToReportErrorStatus"></Phonesuo>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialogcompare" custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <!-- <div class="dialog_detail_title">Pay attention to</div> -->
                <!-- <div class="detail_cont"> -->
                <div class="detail_cont" style="padding-bottom: 20px">
                    <div class="detail_cont_text">• This is a paid feature, open it to view all the available online information about this company. This will cost 1 unit.</div>

                    <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                </div>

                <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                <!-- </div> -->
            </div>
            <div class="flex_center_between_box">
                <el-button class="btn_export" @click="compareCompanyCountry('Company')">OK</el-button>
                <el-button class="btn_export" @click="onSubmitquxiao()">Buy credits</el-button>
            </div>
        </el-dialog>
        <!-- 手机版本 弹框menu -->
        <el-drawer title="" :visible.sync="drawer" size="300px" :with-header="false" direction="ltr">
            <transition name="fade" :duration="500">
                <!-- <div class="right_cont_box phone_page_bg sna_box">
                    <div class="phone_left_menu" v-loading="loadig">
                        <ul class="de_menu_list">
                            <li class="de_menu_item" v-for="(item, index) in menuTitleList" :key="index">
                                <div class="flex_center_between_box" v-if="item.status == false" :class="{ noitclick: item.status == true }">
                                    <span class="de_menu_item_a" :class="{ 'router-link-active': (linkActive == index && $route.name === item.title.replace(/\s/g, '')) || ($route.name == 'ImportAndExport' && linkActive == index), noitclick: item.status == true.valueOf, import: index == '6--1' }" @click="handlFristMenu(index, item, -1), (linkActive = index)">
                                        <div class="title flex_center" :class="['ic_' + index, linkActive == index && linkActive != -1 ? 'subOn' : '']">
                                            {{ item.title }}
                                            <span class="el-icon-lock" style="font-size: 20px; font-weight: 600; position: absolute; left: 200px" v-if="!item.power && item.id != '7001'"></span>
                                        </div>
                                    </span>
                                    <div class="show_ic" @click="showSubMenu(item, index)" v-if="item.result.length != 0" :class="{ open: item.disBol == true, close: item.disBol == false }"></div>
                                </div>
                                <transition name="sub-comments">
                                    <ul class="de_menu_sublist" v-show="item.disBol">
                                        <li class="de_menu_subitem" v-for="(subItem, i) in item.result" :key="i">
                                            <div v-if="subItem.status == false" :class="{ noitclickk: subItem.status == true }" @click="handlFristMenu(index, subItem, i)">
                                                <span class="de_menu_subitem_a" :class="{ 'router-link-active': subLinkActive == index + '-' + i }">
                                                    {{ subItem.name }}
                                                    <span class="el-icon-lock" style="font-size: 16px; font-weight: 600; position: absolute; left: 170px; top: 10px" v-if="!subItem.power"></span>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </transition>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </transition>
        </el-drawer>
        <Phonetocsi v-if="window.innerWidth < 821" :suoClose="moreclose" @closeCloseDialog="closmoreDialog($event)"></Phonetocsi>
    </div>
</template>
<script>
import { getCompanyDetailMenuList, ordersAdd, getIsCollect } from '@/api/companyApi.js';
import { selectGroup, delCollection } from '@/api/searchApi.js';
import { getPdfAddress, getPdfPurchase, getPdfhk_pdf } from '@/api/userApi.js';
import bus from '@/components/bus';
import Collection from '@/components/collection';
import Footer from '@/components/footer';
import Header from '@/components/header';
import Reporterror from '@/components/reportError';
import Suo from '@/components/suo';

import { mixins1 } from '@/mixins/index';
import Phonesuo from '@/components/phonesuo.vue';
import Phonetocsi from '@/components/phonetocsi.vue';
export default {
    components: { Header, Footer, Reporterror, Collection, Suo, Phonesuo, Phonetocsi },
    mixins: [mixins1],
    // inject: ['reload'],
    data() {
        return {
            indexIsTranslate: false,
            isActive: true,
            loadig: true,
            highquality: false,
            Name: '',
            companyBase: {
                name: '',
                nameEn: '',
                aaaId: '',
                companyCountry: '',
                lastUpdate: '',
                // lableActive: '',
                // lableCode: '',
                // lableDate: '',
                tags: [],
            },
            //tags: ['Active', 'MNC', '2003'],
            // isTranslate: false,
            linkActive: sessionStorage.getItem('menuItem') ? sessionStorage.getItem('menuItem') : 0,
            subLinkActive: '',
            menuTitleList: [],
            updateDisabled: false,
            showReportIndex: false,
            suoclose: false,
            suopower: false,
            addPopIndex: false,
            fromPage: 'company',
            xuanzeobj: {},
            collecObj: {},
            addList: [],
            orderStatus: '',
            onlineOrderId: '',
            offlineOrderId: '',
            // powerResult: {},
            token: localStorage.getItem('token'),
            powerToPage: {}, //分发页面权限
            powerToReportErrorStatus: false,
            powerCurStatus: {},
            isfalow: false,
            update: {
                updat: false,
                PDFFile: '',
            },
            newDialog: false,
            drawer: false,
            window: window,
            moreclose: false,
        };
    },
    created() {
        //父路由监听：
        bus.$on('toRightHeadObj', companyBase => {
            this.companyBase = companyBase;
        });
        // this.getMenuList();
        // this.updateSubLinkParent();
        window.addEventListener('beforeunload', this.handlePageRefresh);
    },
    mounted() {
        // window.addEventListener('setItem', this.listenMenuIndexChange);
        window.addEventListener('setItem', () => {
            // this.isfalow=JSON.parse(sessionStorage.getItem('company')).isFavorite
            this.token = localStorage.getItem('token');
            if (this.token) {
                this.getMenuList();

                // this.getCollData();
                // this.hasPdfPurchase();
            }
        });

        this.updateSubLinkParent();
    },

    //页面销毁
    beforeDestroy() {
        // bus.$off('headLogFlg');
        window.removeEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    destroyed() {
        bus.$off('toRightHeadObj');
        // bus.$off('handleShowLogin');
        localStorage.removeItem('menuItem');
        window.removeEventListener('beforeunload', this.handlePageRefresh);
    },
    watch: {
        token: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.getMenuList();
                }
                // this.loadig=false
            },
            immediate: true,
        },
        companyBase: {
            handler(newVal, oldVal) {
                if (newVal.nameEn == '') {
                    // debugger
                    let base = JSON.parse(sessionStorage.getItem('base'));
                    this.companyBase = {
                        name: base.base.name,
                        nameEn: base.base.nameEn,
                        aaaId: this.$route.query.id3a,
                        companyCountry: base.base.countryFlag,

                        lastUpdate: base.base.lastUpdate,
                        tags: base.lables,
                    };
                }

                // this.loadig=false
            },
            immediate: true,
        },
    },
    methods: {
        applefilters() {
            if (window.innerWidth < 821) {
                this.drawer = !this.drawer;
            }
        },
        handlePageRefresh() {
            sessionStorage.removeItem('socket');
            // 在这里执行页面刷新时的操作
        },
        tabshow(e) {
            if (this.isActive) {
                this.isActive = false;
            } else {
                this.isActive = true;
            }
        },
        // 处理鼠标点击事件
        handleonmousedown(e) {
            // 处理登录+模块查看功能：
            const token = localStorage.getItem('token');
            if (!token) {
                //未登录 允许点击接口返回的指定power=true模块
                if (!(e.target?.id === 'fan_yi' || e.target?._prevClass === 'title flex_center subOn' || e.target?.innerText === 'FAQ' || e.target?._prevClass === 'title flex_center ic_0 subOn')) {
                    //
                    // 弹出登录窗口
                    bus.$emit('handleShowLogin', true); //是否显示登录
                }
            }
        },
        updateSubLinkParent(val) {
            if (val == undefined && this.$route.name == 'CompanySnapshot') {
                this.linkActive = 0;

                this.subLinkActive = '';
                return;
            }
            if (val == 0) {
                this.linkActive = 0;
                this.subLinkActive = '';
            } else if (val == 2) {
                this.linkActive = 2;
                this.subLinkActive = '2-0';
            } else if (val == 5) {
                this.linkActive = 5;
                this.subLinkActive = '5-4';
            } else if (val == 8) {
                this.linkActive = 5;
                this.subLinkActive = '';
            } else {
                this.linkActive = val;
                this.subLinkActive = '';
            }
            // this.getMenuList()
        },
        async getContentData() {
            // const companyCountry = this.$route.query.companyCountry;
            // const company = JSON.parse(sessionStorage.getItem('company'));
            this.$nextTick(() => {
                const base = JSON.parse(sessionStorage.getItem('base'));
                const combase = JSON.parse(localStorage.getItem('companyBase1'));
                // debugger
                this.companyBase = {
                    name: this.companyBase.name == '' ? '' : this.companyBase.name || base.base.name,
                    nameEn: this.companyBase.nameEn == '' ? '' : this.companyBase.nameEn || base.base.nameEn,
                    aaaId: this.$route.query.id3a,
                    companyCountry: this.companyBase.companyCountry == '' ? '' : this.companyBase.companyCountry || base.base.countryFlag,

                    lastUpdate: base && base.base ? base.base.lastUpdate : '',
                    tags: this.companyBase.tags.length == 0 ? [] : base.lables || this.companyBase.lables,
                };
                // this.hasPdfPurchase();
            });

            this.getCollData();
        },
        /**
         *用户购买报告 "type": 1 线下，2 线上, 13 Import/Export
         */
        async getOrdersAdd(type) {
            const id3a = this.$route.query.id3a;
            const companyCountry = this.$route.query.companyCountry;
            //1  :线上报告
            let params = {
                companyName: this.companyBase.nameEn, //必须
                aaaId: id3a, //必须
                companyCountry: this.companyBase.companyCountry, //必须
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            let status = '';
            await ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    this.getMenuList();
                    status = true;
                    if (data.msg != 'Saved successfully') {
                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                    }
                } else {
                    if (data.msg != ' Do not repeat the request') {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            // duration: 5000,
                        });
                        let that = this;
                        let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

                        let routeData = '';
                        setTimeout(function () {
                            if (userinfo.type == '3') {
                                routeData = that.$router.resolve({
                                    path: '/account/quota',
                                });
                            } else {
                                routeData = that.$router.resolve({
                                    path: '/account/addCredit',
                                });
                            }

                            window.open(routeData.href, '_blank');
                            if (window.innerWidth < 720) {
                                window.location.href = routeData.href;
                            }
                        }, 2000);

                        status = false;
                    }
                }
            });
            return status;
        },
        // update加锁包一层
        getpdffsuo(pdfName) {
            this.suoclose = true;
            this.update.updat = true;
            this.update.PDFFile = pdfName;
            // this.xuanzeobj=obj
            if (this.suopower) {
                console.log(111);
            }
        },

        //获取 判断是否要扣点 线上报告,线下报告
        getPDFFile(pdfName) {
            // let power =this.powerCurStatus.Update
            // if(power){
            this.pdfName = pdfName;
            if (pdfName === 'onlineReport') {
                if (!this.powerCurStatus.DownloadOnlineReport) {
                    let type = 1;

                    this.getOrdersAdd(type).then(res => {
                        if (res) this.hanldPdfPurchase(pdfName);
                    });
                } else {
                    this.hanldPdfPurchase(pdfName);
                }
            } else {
                if (!this.powerCurStatus.Update) {
                    let type = 2;
                    let that = this;
                    this.getOrdersAdd(type).then(res => {
                        //处理线下报告扣完点加锁的状态
                        if (!that.menuTitleList[3].power && res) that.getOrdersAdd('1');
                        if (res) that.hanldPdfPurchase(pdfName);
                    });
                } else {
                    this.hanldPdfPurchase(pdfName);
                }
            }
            // }else{
            //     this.message()
            // }
        },
        //1.查找订单；2.下载PDF
        async hanldPdfPurchase(pdfName) {
            this.hasPdfPurchase().then(async res => {
                if (pdfName == 'onlineReport' && this.onlineOrderId) {
                    await getPdfAddress(this.onlineOrderId).then(res => {
                        if (res && res.code) {
                            window.open(res.data, '_blank');
                            if (this.window.innerWidth < 720) {
                                window.location.href = res.data;
                            }
                        } else {
                            this.$message({
                                message: 'It normally takes 2-4 days to deliver,this updated rerpot.',
                                duration: 5000,
                            });
                        }
                    });
                    if (this.$route.query.companyCountry == 'HK') {
                        await getPdfhk_pdf(this.onlineOrderId).then(res => {
                            if (res && res.code) {
                                window.open(res.data, '_blank');
                            } else {
                                this.$message({
                                    message: 'It normally takes 2-4 days to deliver,this updated rerpot.',
                                    duration: 5000,
                                });
                            }
                        });
                    }
                } else if (pdfName == 'offlineReport' && this.offlineOrderId) {
                    await getPdfAddress(this.offlineOrderId).then(res => {
                        if (res && res.code) {
                            window.open(res.data, '_blank');
                            if (this.window.innerWidth < 720) {
                                window.location.href = res.data;
                            }
                        }
                    });
                } else {
                    this.$message({
                        message: 'Please purchase PDF',
                        type: 'error',
                        duration: 5000,
                    });
                }
            });
        },
        //是否购买PDF,获取订单号
        async hasPdfPurchase() {
            let params = 'aaaId=' + this.$route.query.id3a;
            await getPdfPurchase(params).then(async res => {
                if (res && res.code) {
                    if (res.data.onlineReport && res.data.offlineReport) {
                        this.onlineOrderId = res.data.onlineReport.id;
                        this.offlineOrderId = res.data.offlineReport.id;
                        this.orderStatus = res.data.offlineReport.orderStatus;
                        if (this.orderStatus == '4') {
                            this.updateDisabled = true;
                        } else {
                            this.updateDisabled = false;
                        }
                    }
                } else {
                    this.updateDisabled = false;
                }
            });
        },
        // 是否翻译
        // getIndexIsTranslate(data) {
        //   this.indexIsTranslate = data
        // },
        btnTranslate() {
            this.indexIsTranslate = !this.indexIsTranslate;
        },
        //compare
        compareCompanyCountrypower(name) {
            const token = localStorage.getItem('token');
            if (!token) {
                //未登录 允许点击接口返回的指定power=true模块

                //
                // 弹出登录窗口
                bus.$emit('handleShowLogin', true); //是否显示登录
            } else {
                const companyCountry = this.$route.query.companyCountry;
                let power = this.powerCurStatus.CompareCompany;
                if (power && name == 'Company') {
                    let routeData = this.$router.resolve({
                        path: '/company/comparecompanys?id3a=' + this.companyBase.aaaId + '&companyCountry=' + companyCountry + '&companyNameEn=' + this.companyBase.nameEn + '&companyNameCn=' + this.companyBase.name,
                    });

                    window.open(routeData.href, '_blank');
                } else {
                    this.newDialog = true;
                }
            }
        },
        closeDialogcompare() {
            this.newDialog = false;
        },
        onSubmitquxiao() {
            // this.newDialog = false;
            // this.$emit('closeCloseDialog', false);
            let that = this;
            let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

            let routeData = '';
            setTimeout(function () {
                if (userinfo.type == '3') {
                    routeData = that.$router.resolve({
                        path: '/account/quota',
                    });
                } else {
                    routeData = that.$router.resolve({
                        path: '/account/addCredit',
                    });
                }

                window.open(routeData.href, '_blank');
            }, 1000);
        },
        compareCompanyCountry(name) {
            const companyCountry = this.$route.query.companyCountry;

            if (this.powerCurStatus.CompareCountry && name == 'Country') {
                // this.getOrdersAdd(1).then(res => {
                // if(res){
                name === 'Company' ? this.$router.push({ path: '/company/comparecompanys', query: { id3a: this.companyBase.aaaId, companyCountry: companyCountry, companyNameEn: this.companyBase.nameEn, companyNameCn: this.companyBase.name } }) : this.$router.push({ path: '/company/comparecountry', query: { id3a: this.companyBase.aaaId, companyCountry: companyCountry } });
                // }
                // });
            } else {
                if (name == 'Country') {
                    this.message();
                } else if (name == 'Company') {
                    this.getOrdersAdd(1).then(res => {
                        this.newDialog = false;
                        let that = this;
                        if (res) {
                            setTimeout(() => {
                                let routeData = that.$router.resolve({
                                    path: '/company/comparecompanys?id3a=' + that.companyBase.aaaId + '&companyCountry=' + companyCountry + '&companyNameEn=' + that.companyBase.nameEn + '&companyNameCn=' + that.companyBase.name,
                                });
                                window.open(routeData.href, '_blank');
                            }, 1500);
                        }
                    });
                }

                // else{
                //     this.getOrdersAdd(1).then(res => {})
                // }

                // name === 'Company' ? this.$router.push({ path: '/company/comparecompanys', query: { id3a: this.companyBase.aaaId, companyCountry: companyCountry, companyNameEn: this.companyBase.nameEn, companyNameCn: this.companyBase.name } }) : this.$router.push({ path: '/company/comparecountry', query: { id3a: this.companyBase.aaaId, companyCountry: companyCountry } });
            }
        },
        //点击Report Error
        detailReportError() {
            let power = this.powerCurStatus.ReportError;
            if (power) {
                this.showReportIndex = true;
                this.powerToReportErrorStatus = this.powerCurStatus.ReportError;
            } else {
                this.message();
            }
        },
        //关闭report弹窗
        closeDialog(value) {
            this.showReportIndex = value;
        },
        //关闭收藏弹窗
        closeCollageDialog(value) {
            // this.addPopIndex = value;
            this.addPopIndex = value[0];
            this.isfalow = value[1];
        },
        // 关闭锁谈框
        clossuoDialog(value) {
            this.suoclose = value;
        },
        closmoreDialog(value) {
            this.moreclose = value;
        },
        clossuoDialog1(value) {
            this.suopower = value;
        },
        //添加列表
        async addToList() {
            console.log(this.isfalow);

            const company = JSON.parse(sessionStorage.getItem('company'));
            let collIds = [];
            let delllsit = [];
            const params = {
                crefoNo: this.$route.query.id3a,
            };
            await getIsCollect(params).then(res => {
                if (res.code) {
                    collIds = res.data;
                    res.data.forEach(item => {
                        delllsit.push(item.id);
                    });
                }
            });
            if (this.isfalow) {
                await delCollection(delllsit).then(res => {
                    if (res) {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'success',
                        });
                        this.isfalow = false;
                        selectGroup().then(res => {
                            if (res && res.code) {
                                this.addList = res.data.rows;
                            }
                        });
                    }
                });
            } else {
                this.collecObj = {
                    company_name_en: this.companyBase.nameEn,
                    aaa_id: this.$route.query.id3a,
                    company_country: this.$route.query.companyCountry,
                    collectionIdList: collIds,
                    isFavorite: this.isfalow,
                };
                this.addPopIndex = true;
            }
        },
        async getCollData() {
            await selectGroup().then(res => {
                if (res && res.code) {
                    this.addList = res.data.rows;
                }
                const params = {
                    crefoNo: this.$route.query.id3a,
                };
                if (this.token) {
                    getIsCollect(params).then(res => {
                        if (res.code) {
                            this.isfalow = true;
                        } else {
                            this.isfalow = false;
                        }
                    });
                }
            });
        },
        //折叠面板
        showSubMenu(el, index) {
            if (el.disBol == false) {
                el.disBol = true;
                return;
            }
            if (el.disBol == true) {
                el.disBol = false;
                return;
            }
        },
        //1.缓存第几个父级高亮,2.点击菜单
        handlFristMenu(index, obj, i) {
            this.update.updat = false;
            this.linkActive = index;
            this.subLinkActive = index + '-' + i;

            sessionStorage.setItem('menuItem', this.linkActive);
            //1.判断是否登录 login: true 需要登录
            if (obj.login && !this.token) {
                bus.$emit('handleShowLogin', true);
            } else {
                //2.判断是否需要扣点 power: false 无权限，需要扣点
                if (!obj.power) {
                    // 锁
                    this.suoclose = true;
                    this.xuanzeobj = obj;
                    if (this.suopower) {
                        let name = obj.title;
                        let name1 = obj.name;
                        this.refreshPowerStatus(name || name1).then(isPower => {
                            if (isPower) this.$router.push({ path: obj.url, query: { id3a: this.$route.query.id3a, companyCountry: this.$route.query.companyCountry, companyCountrydqs: this.$route.query.companyCountrydqs } });
                        });
                    }
                } else {
                    this.$router.push({ path: obj.url, query: { id3a: this.$route.query.id3a, companyCountry: this.$route.query.companyCountry, companyCountrydqs: this.$route.query.companyCountrydqs } });
                }
            }
            this.powerToPage = JSON.stringify(obj.list);
        },
        //选择title 扣点
        async refreshPowerStatus(title) {
            if (title === 'FAQ') {
                let status = await this.getOrdersAdd(1);

                return status;
            } else if (title === 'Similar Companies') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'Company Profile') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'History') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'Management') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'Contact Info') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'Ownership') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'Investment') {
                let status = await this.getOrdersAdd(13);
                return status;
            } else if (title === 'Related Companies') {
                let status = await this.getOrdersAdd(13);
                return status;
            } else if (title === 'Business Operations') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'Industry') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'Performance') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'Financial Highlights') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'Balance Sheet') {
                let status = await this.getOrdersAdd(2);
                return status;
            } else if (title === 'Profit & Loss') {
                let status = await this.getOrdersAdd(2);
                return status;
            } else if (title === 'Ratio Analysis') {
                let status = await this.getOrdersAdd(2);
                return status;
            } else if (title === 'Credit Assessment') {
                let status = await this.getOrdersAdd(2);
                return status;
            } else if (title === 'Import & Export') {
                let status = await this.getOrdersAdd(13);
                return status;
            } else if (title === 'Import') {
                let status = await this.getOrdersAdd(13);
                return status;
            } else if (title === 'Export') {
                let status = await this.getOrdersAdd(13);
                return status;
            } else if (title === 'Supply Chain') {
                let status = await this.getOrdersAdd(13);
                return status;
            } else if (title === 'Publications') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'News Media') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'Compare') {
                let status = await this.getOrdersAdd(1);
                return status;
            } else if (title === 'Update') {
                let status = await this.getOrdersAdd(2);
                return status;
            } else {
                let status = await this.getOrdersAdd(1);
                return status;
            }
        },
        //监听sessionStorage变化
        // listenMenuIndexChange() {
        //     this.linkActive = sessionStorage.getItem('menuItem');
        // },
        //获取目录
        async getMenuList() {
            this.getContentData();
            if (this.token) {
                this.hasPdfPurchase();
            }
            let type = '7';
            let id3a = this.$route.query.id3a;
            let res = await getCompanyDetailMenuList('code=' + id3a + '&type=' + type);
            if (res && res.code) {
                this.loadig = false;
                // this.powerResult = res.data[0];
                let list = res.data;
                var arrLsit = [];
                list.forEach((item, index) => {
                    if (item.type == '1') {
                        var pobj = {};
                        var arr = [];
                        var powerArr = [];
                        if (this.window.innerWidth < 720) {
                            if (item.title == 'Publications' || item.title == 'News Media') {
                                list[index].status = true;
                            }
                        }
                        if (item.list) {
                            let i = 0;
                            item.list.forEach((it, subi) => {
                                if (it.type == '1') {
                                    var obj = {};
                                    if (this.window.innerWidth < 821) {
                                        if (it.title == 'Supply Chain') {
                                            item.list[subi].status = true;
                                        }
                                    }
                                    // 新增处理关联公司财务
                                    if (item.title == 'Import & Export') {
                                        obj = {
                                            id: it.code,
                                            name: it.title,
                                            login: it.login,
                                            power: it.power,
                                            list: it.list,
                                            status: it.status,
                                            // url: '/company/import&export/' + it.title.replace(/\s/g, '').toLowerCase() + '?id3a=' + id3a + '&companyCountry=' + companyCountry,
                                            url: '/company/import&export/' + it.title.replace(/\s/g, '').toLowerCase(),
                                        };
                                        if (this.$route.name === it.title.replace(/\s/g, '')) {
                                            this.subLinkActive = index + '-' + i;
                                            let powerResult = it.list;
                                            !it.power ? this.refreshPowerStatus(it.title) : '';
                                            this.powerToPage = JSON.stringify(powerResult);
                                        }
                                    } else {
                                        obj = {
                                            id: it.code,
                                            name: it.title,
                                            login: it.login,
                                            power: it.power,
                                            list: it.list,
                                            status: it.status,
                                            // url: '/company/' + it.title.replace(/\s/g, '').toLowerCase() + '?id3a=' + id3a + '&companyCountry=' + companyCountry,
                                            url: '/company/' + it.title.replace(/\s/g, '').toLowerCase(),
                                        };
                                        if (this.$route.name === it.title.replace(/\s/g, '')) {
                                            this.subLinkActive = index + '-' + i;
                                            let powerResult = it.list;
                                            !it.power ? this.refreshPowerStatus(it.title) : '';
                                            this.powerToPage = JSON.stringify(powerResult);
                                        }
                                    }

                                    arr.push(obj);
                                    i++;
                                } else if (it.type == '2') {
                                    powerArr.push(it);

                                    if (this.$route.name === item.title.replace(/\s/g, '')) {
                                        this.powerToPage = JSON.stringify(powerArr);
                                    }
                                }
                            });
                        }

                        pobj = {
                            id: item.code,
                            title: item.title,
                            login: item.login,
                            power: item.power,
                            status: item.status,
                            // url: '/company/' + item.title.replace(/\s/g, '').toLowerCase() + '?id3a=' + id3a + '&companyCountry=' + companyCountry,
                            url: '/company/' + item.title.replace(/\s/g, '').toLowerCase(),
                            // img: require('@/assets/img/detail/menu_ic' + (index + 1) + '-1.png'),
                            // img1: require('@/assets/img/detail/menu_ic' + (index + 1) + '-2.png'),
                            disBol: item.power ? true : false,
                            result: arr,
                            list: powerArr,
                        };
                        if (this.$route.name === item.title.replace(/\s/g, '') && !item.power) {
                            this.refreshPowerStatus(item.title);
                        }

                        arrLsit.push(pobj);
                    } else {
                        this.powerCurStatus[item.title.replace(/\s/g, '')] = item.power;
                    }
                });
                this.menuTitleList = arrLsit;
                if (this.window.innerWidth < 720) {
                    arrLsit.map(item => {
                        if (item.result) {
                            item.result.map((ite, ind) => {
                                if (ite.name == 'FAQ') {
                                    item.result[ind].title = 'FAQ';
                                    item.result[ind].result = [];
                                    this.menuTitleList.push(item.result[ind]);
                                }
                                if (ite.name == 'History') {
                                    item.result[ind].title = 'History';
                                    item.result[ind].result = [];
                                    this.menuTitleList.push(item.result[ind]);
                                }
                                if (ite.name == 'Contact Info') {
                                    item.result[ind].title = 'Contact Info';
                                    item.result[ind].result = [];
                                    this.menuTitleList.push(item.result[ind]);
                                }
                                if (ite.name == 'Related Companies') {
                                    item.result[ind].title = 'Related Companies';
                                    item.result[ind].result = [];
                                    this.menuTitleList.push(item.result[ind]);
                                }
                                if (ite.name == 'Industry') {
                                    item.result[ind].title = 'Industry';
                                    item.result[ind].result = [];
                                    this.menuTitleList.push(item.result[ind]);
                                }
                                if (ite.name == 'Credit Assessment') {
                                    item.result[ind].title = 'Credit Assessment';
                                    item.result[ind].result = [];
                                    this.menuTitleList.push(item.result[ind]);
                                }
                            });
                        }
                    });
                }
                // this.subLinkActive=='0-' ? (this.powerToPag = JSON.stringify(this.menuTitleList[0].list)) : this.powerToPag;
            }
        },
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 170px;
    margin-bottom: 80px;
}
/* 头部 */
.flex_between_box {
    display: flex;
    justify-content: space-between;
}
.c_detail_top_right,
.c_detail_top_left {
    margin-top: 30px;
}
.c_d_title {
    width: 490px;
    font-size: 28px;
    font-family: 'Arial Bold';
    line-height: 40px;
    max-height: 74px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 20px;
}
.c_d_tag {
    width: 530px;
}
.c_d_tag .el-tag {
    height: 26px;
    line-height: 26px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #ff7600;
    font-size: 12px;
    color: #ff7600;
    margin: 0 4px 6px;
}
.last_date {
    line-height: 27px;
    font-size: 12px;
    color: #8497ab;
}
.btn_gray,
.btn_blue {
    width: 140px;
    height: 40px;
    line-height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
}
.btn_gray,
.btn_blue:disabled {
    color: #8497ab;
    background: #e7eaed;
}
.btn_gray {
    margin-right: 10px;
}
.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
.c_d_buttom {
    margin-bottom: 40px;
}
.c_d_buttom /deep/ .el-popover__reference-wrapper .btn_blue {
    margin-right: 10px;
}
.c_d_buttom button + button {
    margin-left: 10px;
}
.c_d_icbtn {
    text-align: right;
}
.c_d_icbtn .el-button {
    width: 26px;
    height: 26px;
    background: #022955;
    border-radius: 13px;
    border: none;
    padding: 0;
}
.c_d_icbtn .el-button img {
    width: 14px;
}
.tool_add {
    background: #ff7600 !important;
}
.tool_tip {
    height: 34px;
    font-size: 14px;
    color: #022955;
    background: #ffffff;
    box-shadow: 0px 1px 4px 1px rgba(10, 33, 57, 0.2);
    border-radius: 8px;
    border: none !important;
}
.el-tooltip__popper[x-placement^='bottom'] {
    margin-top: 4px !important;
}
.tool_tip2 {
    width: 174px;
}
.tool_tip3 {
    width: 110px;
}

/* 菜单 */
.left_menu {
    padding: 20px 20px 20px 10px;
    align-self: flex-start;
}
.de_menu_list .menu_ic {
    height: 20px;
    margin-right: 10px;
}
.noitclick {
    pointer-events: none;
    /* color: #8497ab; */
    background: #e7eaed !important;
    cursor: no-drop !important;
    cursor: not-allowed !important;
    position: relative;
    z-index: 99999999;
    /* border-radius: 24px; */
}
.noitclickk {
    pointer-events: none;
    color: #8497ab;
    background: #e7eaed !important;
    /* cursor: no-drop; */
    cursor: not-allowed;
    /* border-radius: 24px; */
}
.de_menu_list .show_ic {
    width: 30px;
    /* height: 30px; */
    text-align: right;
}
.de_menu_list .show_ic img {
    width: 10px;
}
.de_menu_item {
    margin-bottom: 10px;
}
.de_menu_subitem_a {
    display: inline-block;
    color: #8497ab;
}
.de_menu_item_a {
    display: inline-block;
    /* color: #8497ab; */
}
.de_menu_item_a {
    font-size: 16px;
}
.de_menu_item_a .title {
    padding: 10px;
    cursor: pointer;
    color: #022955;
    position: relative;
}
.de_menu_subitem div {
    padding-left: 31px;
}
.de_menu_subitem_a {
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.de_menu_item_a .subOn {
    font-family: 'Arial Bold';
    color: #022955;
    cursor: pointer;
    /* background: #ebf2ff;
    border-radius: 14px; */
}
.de_menu_item_a.router-link-active .title,
.de_menu_subitem_a.router-link-active {
    background-color: #ebf2ff;
    border-radius: 14px;
    color: #022955;
}

/* 折叠面板 */
.sub-comments-leave-active,
.sub-comments-enter-active {
    transition: max-height 0.25s ease;
}
.sub-comments-enter,
.sub-comments-leave-to {
    max-height: 0;
}
.sub-comments-enter-to,
.sub-comments-leave {
    max-height: 600px;
}
.de_menu_sublist {
    will-change: height;
    overflow: hidden;
    /* cursor: not-allowed !important; */
}
.close,
.open {
    transform-origin: center;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
}
.open {
    background-image: url(../../assets/img/search/menu_ic2.png);
}
.close {
    background-image: url(../../assets/img/search/menu_ic1.png);
}

.pop_link {
    padding: 0 0 8px;
    border-radius: 8px;
    line-height: 42px;
    font-size: 16px;

    box-shadow: 0px -8px 20px 0px rgba(10, 33, 57, 0.24);
}
.compare_box li {
    text-align: center;
    margin-top: 4px;
    color: #022955;
    line-height: 42px;
    font-size: 16px;
}
.compare_box li:hover {
    background: rgba(18, 144, 201, 0.1);
    color: #1290c9;
    cursor: pointer;
}
.line_1 {
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
}
@media (max-width: 821px) {
    .Phone_head_title {
        width: 100%;
        padding: 15px;
        box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
        border-radius: 14px;
        background: #ffffff;
        margin-top: 10px;
        flex-wrap: wrap;
    }
    .Phone_head_title .c_detail_top_left {
        margin-top: 0px !important;
        width: 80% !important;
    }
    .c_d_title {
        width: 96% !important;
        font-size: 22px !important;
        line-height: 30px !important;
        margin-bottom: 0px !important;
    }
    .de_menu_item {
        margin-bottom: 0px !important;
    }
    .de_menu_item_a .title {
        text-align: center;
        font-size: 14px !important;
    }

    /* .de_menu_item_a .title.ic_0 {
        background-image: url(../../assets/img/account/menu_ic1_1.png);
        background-repeat: no-repeat;
        background-size: 16px;
        background-position-y: 40%;
    }
    .de_menu_item_a .title.ic_1 {
        background-image: url(../../assets/img/detail/title_ic3.png);
        background-repeat: no-repeat;
        background-size: 16px;
        background-position-y: 40%;
    }
    .de_menu_item_a .title.ic_2 {
        background-image: url(../../assets/img/detail/title_ic1.png);
        background-repeat: no-repeat;
        background-size: 16px;
        background-position-y: 40%;
    }
    .de_menu_item_a .title.ic_3 {
        background-image: url(../../assets/img/company/Ownership11.png);
        background-repeat: no-repeat;
        background-size: 16px;
        background-position-y: 40%;
    }
    .de_menu_item_a .title.ic_4 {
        background-image: url(../../assets/img/company//BUs.png);
        background-repeat: no-repeat;
        background-size: 16px;
        background-position-y: 40%;
    }
    .de_menu_item_a .title.ic_5 {
        background-image: url(../../assets/img/company/permaicon.png);
        background-repeat: no-repeat;
        background-size: 16px;
        background-position-y: 40%;
    }
    .de_menu_item_a .title.ic_6 {
        background-image: url(../../assets/img/detail/title_ic5.png);
        background-repeat: no-repeat;
        background-size: 18px;
        background-position-y: 40%;
    }
    .de_menu_item_a .title.ic_7 {
        background-image: url(../../assets/img/detail/title_ic6.png);
        background-repeat: no-repeat;
        background-size: 18px;
        background-position-y: 40%;
    } */

    .zhankai {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background-color: #1290c9;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .zhankai img {
        height: 18px;
        width: 18px;
    }
    .c_d_icbtn .el-button {
        margin-top: 0px !important;
    }
    .c_detail_top_right,
    .c_detail_top_left {
        margin-top: 0px !important;
    }
    .c_detail_top_right {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .cont_m {
        background: #f1f2f1;
    }
    .right_box {
        background: #f1f2f1 !important;
    }
    .c_d_icbtn {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .phone_flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .phone_flex .btn_export {
        width: 280px;
        border-radius: 30px;
        margin: 20px;
    }
    .lay_container {
        background: #f1f2f1;
    }
    .phone_flex_center_between_box {
        border: 1px solid #ccc;
        padding: 0px 0px;
        border-radius: 30px;
        width: auto;
        margin: 0px 10px;
        white-space: nowrap;
    }
    .de_menu_list {
        display: flex;
        /* flex-wrap: wrap; */
    }
    .compare_box li:hover {
        background: #ebf2ff;
        color: #ebf2ff;
        cursor: pointer;
    }
    .phone_overfflow {
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .de_menu_item_a.router-link-active .title {
        border-radius: 30px !important;
    }

    .phone_overfflow::-webkit-scrollbar {
        display: none;
        background-color: transparent;
        width: 0px !important;
        height: 0px !important;
    }
}
</style>
